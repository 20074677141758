import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, IconButton, Input, Stack, Table, Tabs, VStack } from '@chakra-ui/react';
import { python } from '@codemirror/lang-python';
import CodeMirror from '@uiw/react-codemirror';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from '@/components/basic/SelectField';
import ConditionBuilder from '@/components/ConditionBuilder/ConditionBuilder';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field';
import PropTypes from 'prop-types';
import { Tag } from '@/components/ui/tag';

const DEFAULT_PYTHON_EDITOR = 'def main(**kwargs):';

const formatEventOption = e => ({ label: e.replaceAll('_', ' '), value: e });

const PricingCalculators = ({ calculators, variables, events, fees, onSaveCalculator, onRemoveCalculator }) => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);
  const [feesLabelMap, setFeesLabelMap] = useState();
  const [showFormModal, setShowFormModal] = useState(false);

  const closeFormModal = () => setShowFormModal(false);

  useEffect(() => {
    if (!fees) {
      return;
    }
    setFeesLabelMap(
      fees.reduce((acc, c) => {
        acc[c.uid] = c.label;
        return acc;
      }, {})
    );
  }, [fees]);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      label: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      events: Yup.array().nullable(),
      fees: Yup.array().nullable(),
      condition: Yup.object().nullable(),
      rule_code: Yup.string().nullable().required('Required'),
      action_code: Yup.string().nullable().required('Required'),
    }),
    onSubmit: data => {
      onSaveCalculator(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });

  const openFormModal = (calculator = {}) => {
    resetForm();
    setValues(calculator);
    setShowFormModal(true);
  };

  const removeCalculator = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this item?')) {
      onRemoveCalculator(values);
      closeFormModal();
    }
  };

  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button
          colorScheme="actionPrimary"
          onClick={() =>
            openFormModal({
              rule_code: DEFAULT_PYTHON_EDITOR,
              action_code: DEFAULT_PYTHON_EDITOR,
              events: [],
              fees: [],
            })
          }
        >
          New
        </Button>
      </VStack>
      <Box>
        <Table.Root variant="simple">
          <Table.Header>
            <Table.Row backgroundColor="#f7fafc">
              <Table.ColumnHeader>Label</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Events</Table.ColumnHeader>
              <Table.ColumnHeader>Fees</Table.ColumnHeader>
              <Table.ColumnHeader>Condition</Table.ColumnHeader>
              <Table.ColumnHeader>&nbsp;</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {calculators &&
              calculators?.map(item => (
                <Table.Row key={item.uid} borderBottom="1px solid #e4edf3">
                  <Table.Cell>{item.label}</Table.Cell>
                  <Table.Cell>{item.description}</Table.Cell>
                  <Table.Cell>
                    {(item.events || []).map(e => (
                      <div key={e}>
                        <Tag marginBottom="6px">{e.replaceAll('_', ' ')}</Tag>
                        <br />
                      </div>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {(item.fees || []).map(f => (
                      <div key={f}>
                        <Tag marginBottom="6px">{feesLabelMap && feesLabelMap[f]}</Tag>
                        <br />
                      </div>
                    ))}
                  </Table.Cell>
                  <Table.Cell>{item?.condition?.pythonCondition}</Table.Cell>
                  <Table.Cell>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <DialogRoot size="xl" open={showFormModal} onClose={closeFormModal}>
        <DialogContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <DialogHeader><Heading as="h3" fontSize="lg">Calculator</Heading></DialogHeader>
            <DialogCloseTrigger asChild onClick={closeFormModal} />
            <DialogBody>
              <Stack gap="16px">
                <Field
                  id="label"
                  invalid={errors.label && touched.label}
                  label={
                    <>
                      Label&nbsp;
                      <Tooltip
                        content="Enter a unique name to identify this calculator within the Pricing Engine"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.label}
                >
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                </Field>
                <Field
                  id="description"
                  invalid={errors.description && touched.description}
                  label={
                    <>
                      Description&nbsp;
                      <Tooltip
                        content="Provide a detailed explanation of the calculator's purpose and intended use"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.description}
                >
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </Field>
                <Field
                  id="events"
                  invalid={errors.events && touched.events}
                  label={
                    <>
                      Events&nbsp;
                      <Tooltip
                        content="Select the event during which this calculator should be triggered, such as during the creation or update of a PO, or during invoicing."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  errorText={errors.events}
                >
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: false,
                      removeSelected: true,
                      isMulti: true,
                      name: 'events',
                      value: (values.events || []).map(formatEventOption),
                      onChange: ev => {
                        setFieldValue(
                          'events',
                          ev.map(e => e.value)
                        );
                      },
                      options: (events || []).map(formatEventOption),
                    }}
                  />
                </Field>
                <Field
                  id="condition"
                  invalid={errors.condition && touched.condition}
                  label={
                    <>
                      Condition&nbsp;
                      <Tooltip
                        content="Define the variable rules and conditions that determine how this calculator will operate."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.condition}
                >
                  {variables && (
                    <ConditionBuilder
                      condition={values.condition}
                      variables={variables.map(({ uid, label, ops }) => ({ name: uid, label, ops }))}
                      onUpdateCondition={con => {
                        setFieldValue('condition', con);
                      }}
                    />
                  )}
                </Field>
                <Field
                  id="fees"
                  invalid={errors.fees && touched.fees}
                  label={
                    <>
                      Fees&nbsp;
                      <Tooltip
                        content="Select the appropriate fees, as defined under the Fees Rules in the Fees Tab, to apply within this calculator."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  errorText={errors.fees}
                >
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: false,
                      removeSelected: true,
                      isMulti: true,
                      name: 'fees',
                      value: (values.fees || []).map(f => ({ label: feesLabelMap[f], value: f })),
                      onChange: fs => {
                        setFieldValue(
                          'fees',
                          fs.map(e => e.value)
                        );
                      },
                      options: (fees || []).map(f => ({ label: f.label, value: f.uid })),
                    }}
                  />
                </Field>
                <Tabs.Root defaultValue="Rulecode" width="100%">
                  <Tabs.List>
                    <Tabs.Trigger value="Rulecode">Rule code *</Tabs.Trigger>
                    <Tabs.Trigger value="Actioncode">Action code *</Tabs.Trigger>
                  </Tabs.List>

                  <Tabs.Content value="Rulecode" width="100%">
                    <Field
                      id="rule_code"
                      invalid={errors.rule_code && touched.rule_code}
                      label={
                        <>
                          Rule code&nbsp;
                          <Tooltip
                            content="Enter the technical code that defines how the calculation should be performed"
                            positioning={{ placement: 'right-end' }}
                          >
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              variant="unstyled"
                            >
                              <IoInformationCircleOutline size="14px" />
                            </IconButton>
                          </Tooltip>
                          *
                        </>
                      }
                      errorText={errors.rule_code}
                    >
                      <CodeMirror
                        value={values.rule_code}
                        height="500px"
                        width="100%"
                        extensions={[python({})]}
                        onChange={rule_code => {
                          setFieldValue('rule_code', rule_code);
                        }}
                      />
                    </Field>
                  </Tabs.Content>
                  <Tabs.Content value="Actioncode" width="100%">
                    <Field
                      id="action_code"
                      invalid={errors.action_code && touched.action_code}
                      label={
                        <>
                          Action code&nbsp;
                          <Tooltip
                            content="Enter the technical code that specifies how the output of the calculation should be displayed, based on the Rule Code"
                            positioning={{ placement: 'right-end' }}
                          >
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              variant="unstyled"
                            >
                              <IoInformationCircleOutline size="14px" />
                            </IconButton>
                          </Tooltip>
                          *
                        </>
                      }
                      errorText={errors.action_code}
                    >
                      <CodeMirror
                        value={values.action_code}
                        width="100%"
                        height="500px"
                        extensions={[python({})]}
                        onChange={action_code => {
                          setFieldValue('action_code', action_code);
                        }}
                      />
                    </Field>
                  </Tabs.Content>
                </Tabs.Root>
              </Stack>
            </DialogBody>
            <DialogFooter>
              <Checkbox
                marginRight="auto"
                checked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" backgroundColor="error.500" onClick={removeCalculator} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" disabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};

export default PricingCalculators;

PricingCalculators.propTypes = {
  calculators: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      events: PropTypes.arrayOf(PropTypes.string),
      fees: PropTypes.arrayOf(PropTypes.string),
      condition: PropTypes.shape({
        pythonCondition: PropTypes.string,
      }),
    })
  ),
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      ops: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  events: PropTypes.arrayOf(PropTypes.string),
  fees: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onSaveCalculator: PropTypes.func,
  onRemoveCalculator: PropTypes.func,
};
