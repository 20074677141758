import React, { useState } from 'react';
import { Box, Button, IconButton, Input, Spinner, Stack, Tabs, Textarea } from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import Select from '@/components/basic/SelectField';
import { getInternalPoLabel } from '@/utils';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Field } from '@/components/ui/field';
import PropTypes from 'prop-types';

const PO_FROM = {
  internal_po_number: 'internal_po_number',
  grinder_po_number: 'grinder_po_number',
  packer_so_number: 'packer_so_number',
  id: 'id',
  fake_load: 'fake_load',
};

const textStyle = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,monospace',
  fontSize: '1em',
};

const formatCalculatorOption = item => ({ label: item.label, value: item.uid });

const PricingExecutor = ({ mode = 'test', onPricing, calculators, configs }) => {
  const [poFrom, setPoFrom] = useState(Object.keys(PO_FROM)[0]);
  const [poFromValue, setPoFromValue] = useState();
  const [pricingCalculatorUid, setPricingCalculatorUid] = useState();
  const [testResult, setTestResult] = useState();
  const [isLoading, setLoading] = useState();

  const onPricingLoad = () => {
    setTestResult(null);
    setLoading(true);
    onPricing({ load_from: poFrom, load_from_value: poFromValue, pricing_calculator_uid: pricingCalculatorUid, mode })
      .then(response => {
        setTestResult(response.data);
      })
      .finally(() => setLoading(false));
  };
  return (
    <Box>
      <Stack gap="16px">
        <Field
          label={
            <>
              PO from&nbsp;
              <Tooltip
                content="Select the PO from where the PO needs to come to test the Pricing Engine."
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          <Select
            {...{
              isClearable: true,
              isSearchable: true,
              closeMenuOnSelect: true,
              removeSelected: true,
              isMulti: false,
              value: poFrom && calculators && { value: poFrom, label: PO_FROM[poFrom].replaceAll('_', ' ') },
              onChange: pform => {
                setPoFrom(pform.value);
                setPoFromValue('');
              },
              options: Object.keys(PO_FROM).map(key => ({ value: key, label: PO_FROM[key].replaceAll('_', ' ') })),
            }}
          />
        </Field>
        {PO_FROM.internal_po_number === poFrom && (
          <Field
            label={
              <>
                {getInternalPoLabel(configs)}&nbsp;
                <Tooltip
                  content={`Provide the ${getInternalPoLabel(configs)} to test the Pricing Engine.`}
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <Input type="number" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </Field>
        )}
        {PO_FROM.grinder_po_number === poFrom && (
          <Field label="Grinder PO #">
            <Input type="text" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </Field>
        )}
        {PO_FROM.packer_so_number === poFrom && (
          <Field label="Packer SO #">
            <Input type="text" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </Field>
        )}
        {PO_FROM.id === poFrom && (
          <Field label="PO ID #">
            <Input type="number" onChange={e => setPoFromValue(e.target.value)} value={poFromValue} />
          </Field>
        )}
        {PO_FROM.fake_load === poFrom && (
          <Field label="Fake load">
            <Textarea
              marginBottom="10px"
              onChange={e => setPoFromValue(e.target.value)}
              value={poFromValue}
              height={300}
            />
            <Button
              colorScheme="teal"
              size="xs"
              onClick={() =>
                setPoFromValue(
                  JSON.stringify(
                    {
                      id: 142972,
                      end_user_id: 1,
                      delivery_date: '2023-03-30',
                      grinder_uid: 'OSIIL',
                      packer_plant_id: 'CARDC',
                      transport_cost_per_unit: 0.11,
                      lines: [
                        {
                          id: 194194,
                          line_number: 1,
                          buy_price_per_unit: 2.76,
                          price_per_unit: 2.65,
                          input_product: {
                            product_type: 'BEEF',
                          },
                        },
                      ],
                    },
                    null,
                    2
                  )
                )
              }
            >
              Load example
            </Button>
          </Field>
        )}
        <Field
          label={
            <>
              Calculator&nbsp;
              <Tooltip
                content="Select the Calculator created under Calculator to test the Pricing Engine."
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          <Select
            {...{
              isClearable: true,
              isSearchable: true,
              closeMenuOnSelect: true,
              removeSelected: true,
              isMulti: false,
              value:
                pricingCalculatorUid &&
                calculators &&
                formatCalculatorOption({
                  id: pricingCalculatorUid,
                  label: calculators.find(c => c.uid === pricingCalculatorUid).label,
                }),
              onChange: calculator => {
                setPricingCalculatorUid(calculator.value);
              },
              options: (calculators || []).map(formatCalculatorOption),
            }}
          />
        </Field>
      </Stack>
      <Box marginY="32px">
        {testResult && (
          <Tabs.Root defaultValue="Pricing" isFitted isLazy>
            <Tabs.List>
              <Tabs.Trigger value="Pricing">Pricing</Tabs.Trigger>
              <Tabs.Trigger value="Documents">Documents</Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="Pricing">
              {testResult?.logs?.map((t, i) => {
                const { type, data } = t;
                let content = null;
                if (type === 'title') {
                  content = (
                    <p style={textStyle}>
                      <b>{data}</b>
                    </p>
                  );
                } else if (type === 'text' || type === 'debug') {
                  content = <p style={textStyle}>{data}</p>;
                } else if (type === 'json') {
                  content = (
                    <ReactJson
                      name={false}
                      src={data}
                      collapsed={1}
                      enableClipboard={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                    />
                  );
                } else if (type === 'exception') {
                  content = <p style={{ ...textStyle, color: '#E53E3E' }}>{data}</p>;
                } else if (type === 'html') {
                  // eslint-disable-next-line react/no-danger-with-children, react/no-danger
                  content = <div dangerouslySetInnerHTML={{ __html: data }}>&nbsp;</div>;
                }
                return <div key={i}>{content}</div>;
              })}
            </Tabs.Content>
            <Tabs.Content value="Documents">
              {testResult?.documents?.map(d => (
                <Box key={d.label} py="2em" my="2em" borderBottom="1px" borderColor="#CCC">
                  <p style={textStyle}>
                    <b>{d.label}</b>
                  </p>
                  {/* eslint-disable-next-line react/no-danger-with-children, react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: d.preview }}>&nbsp;</div>
                </Box>
              ))}
            </Tabs.Content>
          </Tabs.Root>
        )}
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Spinner />
          </div>
        )}
      </Box>
      <Button type="button" colorScheme="actionPrimary" onClick={onPricingLoad}>
        {mode === 'test' && 'Test'}
      </Button>
    </Box>
  );
};

export default PricingExecutor;

PricingExecutor.propTypes = {
  mode: PropTypes.string,
  onPricing: PropTypes.func,
  calculators: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  configs: PropTypes.shape({
    // Define the shape of the configs object here
  }),
};
