import { Box, Button, IconButton, Input, Stack, Table, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import Select from '@/components/basic/SelectField';
import PricingFeeRates from './PricingFeeRates/PricingFeeRates';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Field } from '@/components/ui/field';
import PropTypes from 'prop-types';
import { Tag } from '@/components/ui/tag';

const formatColumnOption = c => ({ label: c.replace(':', ' => '), value: c });

const PricingFees = ({ fees, feeRateLookupColumns, feeRateStateColumns, onSaveFee, onRemoveFee, axiosConfig }) => {
  const [selectedFee, setSelectedFee] = useState();
  const [feeRates, setFeeRates] = useState();
  const [grinders, setGrinders] = useState();
  const [packerPlants, setPackerPlants] = useState();
  const [endUsers, setEndUsers] = useState();
  const [uoms, setUoms] = useState();
  const [coldStores, setColdStores] = useState();
  const [countries, setCountries] = useState();
  const [productTypes, setProductTypes] = useState();
  const [currencies, setCurrencies] = useState();
  const [incoterms, setIncoterms] = useState();
  const [productCategories, setProductCategories] = useState();
  const [ports, setPorts] = useState();
  const [showFormModal, setShowFormModal] = useState(false);

  const closeFormModal = () => setShowFormModal(false);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      uid: Yup.string().nullable().required('Required'),
      label: Yup.string().nullable().required('Required'),
      abbreviation: Yup.string().nullable().required('Required'),
      category: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      lookup_columns: Yup.array().nullable().required('Required'),
      state_columns: Yup.array().nullable().required('Required'),
    }),
    onSubmit: data => {
      onSaveFee(data);
      closeFormModal();
    },
  });

  const openFormModal = (fee = {}) => {
    resetForm();
    setValues(fee);
    setShowFormModal(true);
  };

  const removeFee = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this item?')) {
      onRemoveFee(values);
      closeFormModal();
    }
  };

  /* Ports */
  const loadPorts = () => {
    axios.get(`${Constants.URL}ports`, axiosConfig).then(response => {
      setPorts(response.data.map(item => ({ label: item.name, value: item.id })));
    });
  };

  /* Grinders */
  const loadGrinders = () => {
    axios.get(`${Constants.URL}grinders`, axiosConfig).then(response => {
      setGrinders(response.data.map(g => ({ label: `(${g.uid}) ${g.name}`, value: g.uid })));
    });
  };

  /* Packer plants */
  const loadPackerPlants = () => {
    axios.get(`${Constants.URL}packer_plants`, axiosConfig).then(response => {
      setPackerPlants(response.data.map(pp => ({ label: `(${pp.id}) ${pp.name}`, value: pp.id })));
    });
  };

  /* Customers */
  const loadEndUsers = () => {
    axios.get(`${Constants.URL}customers`, axiosConfig).then(response => {
      setEndUsers(response.data.map(eu => ({ label: `(${eu.id}) ${eu.common_name}`, value: eu.id })));
    });
  };

  /* Unit of measures */
  const loadUnitOfMeasures = () => {
    axios.get(`${Constants.URL}units`, axiosConfig).then(response => {
      setUoms(response.data.map(item => ({ label: item.name, value: item.id })));
    });
  };

  /* Cold stores */
  const loadColdStores = () => {
    axios.get(`${Constants.URL}cold_stores`, axiosConfig).then(response => {
      setColdStores(response.data.map(item => ({ label: item.company_name, value: item.id })));
    });
  };

  /* Countries */
  const loadCountries = () => {
    axios.get(`${Constants.URL}countries`, axiosConfig).then(response => {
      setCountries(response.data.map(item => ({ label: item.name, value: item.name })));
    });
  };

  /* Form Fields */
  const loadFormFields = () => {
    axios.get(`${Constants.URL}form_fields`, axiosConfig).then(response => {
      const formFields = response.data.reduce((o, c) => {
        if (!Array.isArray(o[c.type])) {
          // eslint-disable-next-line no-param-reassign
          o[c.type] = [];
        }
        o[c.type].push(c);
        return o;
      }, {});
      setProductTypes(formFields.product_types.map(item => ({ label: item.value, value: item.value })));
      setCurrencies(formFields.currency.map(item => ({ label: item.value, value: item.value })));
      setIncoterms(formFields.incoterms.map(item => ({ label: item.value, value: item.value })));
      setProductCategories(formFields.input_product_category.map(item => ({ label: item.value, value: item.value })));
    });
  };

  /* Fee rates */
  const loadFeeRates = () => {
    axios.get(`${Constants.URL}pricing/fee-rates?fee_uid=${selectedFee.uid}`, axiosConfig).then(response => {
      setFeeRates(response.data);
    });
  };

  const onSaveFeeRate = feeRate => {
    (!feeRate.created
      ? axios.post(`${Constants.URL}pricing/fee-rates`, { ...feeRate, fee_uid: selectedFee.uid }, axiosConfig)
      : axios.patch(`${Constants.URL}pricing/fee-rates/${feeRate.id}`, feeRate, axiosConfig)
    ).then(loadFeeRates);
  };

  const onRemoveFeeRate = feeRate => {
    axios.delete(`${Constants.URL}pricing/fee-rates/${feeRate.id}`, axiosConfig).then(loadFeeRates);
  };

  useEffect(() => {
    if (selectedFee) {
      loadFeeRates();
    }
  }, [selectedFee]);

  useEffect(() => {
    if (!axiosConfig) {
      return;
    }
    loadGrinders();
    loadPackerPlants();
    loadEndUsers();
    loadUnitOfMeasures();
    loadColdStores();
    loadFormFields();
    loadCountries();
    loadPorts();
  }, [axiosConfig]);

  if (selectedFee) {
    return (
      <PricingFeeRates
        selectedFee={selectedFee}
        setSelectedFee={setSelectedFee}
        setFeeRates={setFeeRates}
        feeRates={feeRates}
        grinders={grinders}
        packerPlants={packerPlants}
        endUsers={endUsers}
        uoms={uoms}
        productTypes={productTypes}
        currencies={currencies}
        countries={countries}
        incoterms={incoterms}
        productCategories={productCategories}
        coldStores={coldStores}
        ports={ports}
        onSaveFeeRate={onSaveFeeRate}
        onRemoveFeeRate={onRemoveFeeRate}
      />
    );
  }
  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button colorScheme="actionPrimary" onClick={() => openFormModal()}>
          New
        </Button>
      </VStack>
      <Box>
        <Table.Root variant="simple">
          <Table.Header>
            <Table.Row backgroundColor="#f7fafc">
              <Table.ColumnHeader>Label</Table.ColumnHeader>
              <Table.ColumnHeader>Abbreviation</Table.ColumnHeader>
              <Table.ColumnHeader>Category</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Lookup Columns</Table.ColumnHeader>
              <Table.ColumnHeader>State Columns</Table.ColumnHeader>
              <Table.ColumnHeader>&nbsp;</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {fees &&
              fees.map(item => (
                <Table.Row key={item.uid} borderBottom="1px solid #e4edf3">
                  <Table.Cell
                    _hover={{ bg: 'blue.500', color: ' white' }}
                    cursor="pointer"
                    onClick={() => setSelectedFee(item)}
                  >
                    {item.label}
                  </Table.Cell>
                  <Table.Cell>{item.abbreviation}</Table.Cell>
                  <Table.Cell>{item.category}</Table.Cell>
                  <Table.Cell>{item.description}</Table.Cell>
                  <Table.Cell>
                    {(item.lookup_columns || []).map(formatColumnOption).map(c => (
                      <div key={c.value}>
                        <Tag marginBottom="6px">{c.label}</Tag>
                        <br />
                      </div>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {(item.state_columns || []).map(formatColumnOption).map(c => (
                      <div key={c.value}>
                        <Tag marginBottom="6px">{c.label}</Tag>
                        <br />
                      </div>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <DialogRoot size="6xl" open={showFormModal} onClose={closeFormModal}>
        <DialogContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <DialogHeader>Fee</DialogHeader>
            <DialogCloseTrigger asChild onClick={closeFormModal} />
            <DialogBody>
              <Stack gap="10">
                <Field
                  id="label"
                  invalid={errors.label && touched.label}
                  label={
                    <>
                      Label&nbsp;
                      <Tooltip
                        content="Enter a unique name to identify this fee within the Pricing Engine"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.label}
                >
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                </Field>
                <Field
                  id="abbreviation"
                  invalid={errors.abbreviation && touched.abbreviation}
                  label={
                    <>
                      Abbreviation&nbsp;
                      <Tooltip
                        content="Provide the abbreviation or short form that represents this fee"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.abbreviation}
                >
                  <Input
                    name="abbreviation"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.abbreviation}
                  />
                </Field>
                <Field
                  id="category"
                  invalid={errors.category && touched.category}
                  label={
                    <>
                      Category&nbsp;
                      <Tooltip content="Specify the category to which this fee belongs" positioning={{ placement: 'right-end' }}>
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.category}
                >
                  <Input
                    name="category"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.category}
                  />
                </Field>
                <Field
                  id="description"
                  invalid={errors.description && touched.description}
                  label={
                    <>
                      Description&nbsp;
                      <Tooltip
                        content="Provide detailed information about this fee to better understand its purpose"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.description}
                >
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </Field>
                <Field id="uid" invalid={errors.uid && touched.uid} label="Name *" errorText={errors.uid}>
                  <Input
                    name="uid"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.uid}
                    readOnly={values.created}
                  />
                </Field>
                <Field
                  id="lookup_columns"
                  invalid={errors.lookup_columns && touched.lookup_columns}
                  label={
                    <>
                      Lookup Columns&nbsp;
                      <Tooltip
                        content="Select the fields that should be used to look up the relevant data for this fee."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.lookup_columns}
                >
                  <Select
                    isClearable
                    isSearchable
                    closeMenuOnSelect={false}
                    removeSelected
                    isMulti
                    name="lookup_columns"
                    value={(values.lookup_columns || []).map(formatColumnOption)}
                    onChange={lookup_columns => {
                      setFieldValue(
                        'lookup_columns',
                        lookup_columns.map(c => c.value)
                      );
                    }}
                    options={(feeRateLookupColumns || []).map(formatColumnOption)}
                  />
                </Field>
                <Field
                  id="state_columns"
                  invalid={errors.state_columns && touched.state_columns}
                  label={
                    <>
                      State Columns&nbsp;
                      <Tooltip
                        content="Select the state in which this fee needs to be calculated, such as Rate, Free Days, or Roll Period Days"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.state_columns}
                >
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: false,
                      removeSelected: true,
                      isMulti: true,
                      name: 'state_columns',
                      value: (values.state_columns || []).map(formatColumnOption),
                      onChange: state_columns => {
                        setFieldValue(
                          'state_columns',
                          state_columns.map(c => c.value)
                        );
                      },
                      options: (feeRateStateColumns || []).map(formatColumnOption),
                    }}
                  />
                </Field>
              </Stack>
            </DialogBody>
            <DialogFooter>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={removeFee} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" disabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};

export default PricingFees;

PricingFees.propTypes = {
  fees: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      abbreviation: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      lookup_columns: PropTypes.arrayOf(PropTypes.string).isRequired,
      state_columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  feeRateLookupColumns: PropTypes.arrayOf(PropTypes.string),
  feeRateStateColumns: PropTypes.arrayOf(PropTypes.string),
  onSaveFee: PropTypes.func,
  onRemoveFee: PropTypes.func,
  axiosConfig: PropTypes.shape({}),
};
