import { Box, Button, IconButton, Input, Stack, Table, Tabs, Textarea, VStack } from '@chakra-ui/react';
import { python } from '@codemirror/lang-python';
import { html } from '@codemirror/lang-html';
import CodeMirror from '@uiw/react-codemirror';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Select from '../../../components/basic/SelectField';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field';
import PropTypes from 'prop-types';

const DEFAULT_PYTHON_EDITOR = 'def main(**kwargs):';

const PricingDocumentTemplate = ({
  documentTemplates,
  calculators,
  onSaveDocumentTemplate,
  onRemoveDocumentTemplate,
}) => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);

  const [calculatorLabels, setCalculatorLabels] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);

  const closeFormModal = () => setShowFormModal(false);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      label: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      uid: Yup.string().nullable().required('Required'),
      calculator_uid: Yup.string().nullable().required('Required'),
      email_subject: Yup.string().nullable().required('Required'),
      email_to: Yup.string().nullable().required('Required'),
      email_body: Yup.string().nullable().required('Required'),
      template: Yup.string().nullable().required('Required'),
      template_data_code: Yup.string().nullable().required('Required'),
    }),
    onSubmit: data => {
      // eslint-disable-next-line no-param-reassign
      data.email_to = !Array.isArray(data.email_to) ? data.email_to.split(',') : data.email_to;
      onSaveDocumentTemplate(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });

  const removeVariable = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this item?')) {
      onRemoveDocumentTemplate(values);
      closeFormModal();
    }
  };

  const openFormModal = (documentTemplate = {}) => {
    resetForm();
    setValues(documentTemplate);
    setShowFormModal(true);
  };

  useEffect(() => {
    if (calculators) {
      setCalculatorLabels(
        calculators.reduce((o, c) => {
          // eslint-disable-next-line no-param-reassign
          o[c.uid] = c.label;
          return o;
        }, {})
      );
    }
  }, [calculators]);

  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button
          colorScheme="actionPrimary"
          onClick={() => openFormModal({ template_data_code: DEFAULT_PYTHON_EDITOR })}
        >
          New
        </Button>
      </VStack>
      <Box>
        <Table.Root variant="simple">
          <Table.Header>
            <Table.Row backgroundColor="#f7fafc">
              <Table.ColumnHeader>Label</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Calculator</Table.ColumnHeader>
              <Table.ColumnHeader>&nbsp;</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {documentTemplates &&
              documentTemplates.map(item => (
                <Table.Row key={item.uid} borderBottom="1px solid #e4edf3">
                  <Table.Cell>{item.label}</Table.Cell>
                  <Table.Cell>{item.description}</Table.Cell>
                  <Table.Cell>{calculatorLabels[item.calculator_uid]}</Table.Cell>
                  <Table.Cell>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <DialogRoot size="6xl" open={showFormModal} onClose={closeFormModal}>
        <DialogContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <DialogHeader>Document Template</DialogHeader>
            <DialogCloseTrigger asChild onClick={closeFormModal} />
            <DialogBody>
              <Stack gap="10">
                <Field
                  id="label"
                  invalid={errors.label && touched.label}
                  label={
                    <>
                      Label&nbsp;
                      <Tooltip
                        content="A unique name to identify this Document Template within the Pricing Engine."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.label}
                >
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                </Field>
                <Field
                  id="description"
                  invalid={errors.description && touched.description}
                  label={
                    <>
                      Description&nbsp;
                      <Tooltip
                        content="Detailed information about this Document Template to better understand its purpose."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.description}
                >
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </Field>
                <Field id="uid" invalid={errors.uid && touched.uid} label="Name *" errorText={errors.uid}>
                  <Input
                    name="uid"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.uid}
                    readOnly={values.created}
                  />
                </Field>
                <Field
                  id="calculator_uid"
                  invalid={errors.calculator_uid && touched.calculator_uid}
                  label={
                    <>
                      Calculator&nbsp;
                      <Tooltip
                        content="Select the Calculator while it is created under Calculator."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.calculator_uid}
                >
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      name: 'calculator_uid',
                      value: values.calculator_uid && {
                        label: calculatorLabels[values.calculator_uid],
                        value: values.calculator_uid,
                      },
                      onChange: calculator_uid => {
                        setFieldValue('calculator_uid', calculator_uid?.value);
                      },
                      options: (calculators || []).map(c => ({ label: c.label, value: c.uid })),
                    }}
                  />
                </Field>
                <Field
                  id="email_subject"
                  invalid={errors.email_subject && touched.email_subject}
                  label={
                    <>
                      Email subject&nbsp;
                      <Tooltip
                        content="The Email subject for the document template."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.email_subject}
                >
                  <Input
                    name="email_subject"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email_subject}
                  />
                </Field>
                <Field
                  id="email_to"
                  invalid={errors.email_to && touched.email_to}
                  label={
                    <>
                      Email to&nbsp;
                      <Tooltip
                        content="The recipient Email for the document template."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.email_to}
                >
                  <Input
                    name="email_to"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email_to}
                  />
                </Field>
                <Field
                  id="email_body"
                  invalid={errors.email_body && touched.email_body}
                  label={
                    <>
                      Email body&nbsp;
                      <Tooltip
                        content="The Email body for the document template."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.email_body}
                >
                  <Textarea name="email_body" onChange={handleChange} onBlur={handleBlur} value={values.email_body} />
                </Field>
                <Tabs.Root defaultValue="Template">
                  <Tabs.List>
                    <Tabs.Trigger value="Template">Template *</Tabs.Trigger>
                    <Tabs.Trigger value="Data Code">Data Code *</Tabs.Trigger>
                  </Tabs.List>

                  <Tabs.Content value="Template">
                    <Field
                      id="template"
                      invalid={errors.template && touched.template}
                      label="Template *"
                      errorText={errors.template}
                    >
                      <CodeMirror
                        value={values.template}
                        height="500px"
                        extensions={[html({})]}
                        onChange={template => {
                          setFieldValue('template', template);
                        }}
                      />
                    </Field>
                  </Tabs.Content>
                  <Tabs.Content value="Data Code">
                    <Field
                      id="template_data_code"
                      invalid={errors.template_data_code && touched.template_data_code}
                      label="Data Code *"
                      errorText={errors.template_data_code}
                    >
                      <CodeMirror
                        value={values.template_data_code}
                        height="500px"
                        extensions={[python({})]}
                        onChange={template_data_code => {
                          setFieldValue('template_data_code', template_data_code);
                        }}
                      />
                    </Field>
                  </Tabs.Content>
                </Tabs.Root>
              </Stack>
            </DialogBody>
            <DialogFooter>
              <Checkbox
                marginRight="auto"
                checked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={removeVariable} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" disabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};

export default PricingDocumentTemplate;

PricingDocumentTemplate.propTypes = {
  documentTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      calculator_uid: PropTypes.string.isRequired,
      email_subject: PropTypes.string.isRequired,
      email_to: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
      email_body: PropTypes.string.isRequired,
      template: PropTypes.string.isRequired,
      template_data_code: PropTypes.string.isRequired,
    })
  ),
  calculators: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onSaveDocumentTemplate: PropTypes.func,
  onRemoveDocumentTemplate: PropTypes.func,
};
