import { Box, Button, Heading, IconButton, Input, Stack, Table, VStack } from '@chakra-ui/react';
import { python } from '@codemirror/lang-python';
import CodeMirror from '@uiw/react-codemirror';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Select from '../../../components/basic/SelectField';
import { Field } from '@/components/ui/field';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Checkbox } from '@/components/ui/checkbox';
import PropTypes from 'prop-types';
import { Alert } from '@/components/ui/alert';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const VARIBLE_TYPES = ['constant', 'function'];
const VARIBLE_RETURN_TYPES = ['str', 'int', 'float', 'bool'];
const DEFAULT_PYTHON_EDITOR = 'def main(**kwargs):';

const formatOption = item => ({ label: item, value: item });

const PricingVariables = ({ variables, onSaveVariable, onRemoveVariable }) => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);

  const closeFormModal = () => setShowFormModal(false);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      label: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      uid: Yup.string().nullable().required('Required'),
      value: Yup.string().nullable().required('Required'),
      type: Yup.string().nullable().required('Required'),
      return_type: Yup.string().nullable().required('Required'),
    }),
    onSubmit: data => {
      onSaveVariable(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });

  const openFormModal = (variable = {}) => {
    resetForm();
    setValues(variable);
    setShowFormModal(true);
  };

  const removeVariable = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this item?')) {
      onRemoveVariable(values);
      closeFormModal();
    }
  };

  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button colorScheme="actionPrimary" onClick={openFormModal}>
          New
        </Button>
      </VStack>
      <Box>
        <Table.Root variant="simple">
          <Table.Header>
            <Table.Row backgroundColor="#f7fafc">
              <Table.ColumnHeader>Label</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Value</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Return type</Table.ColumnHeader>
              <Table.ColumnHeader>&nbsp;</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {variables &&
              variables.map(item => (
                <Table.Row key={item.uid} borderBottom="1px solid #e4edf3">
                  <Table.Cell>{item.label}</Table.Cell>
                  <Table.Cell>{item.description}</Table.Cell>
                  <Table.Cell>{item.uid}</Table.Cell>
                  <Table.Cell>{item.type !== 'function' ? item.value : '(code)'}</Table.Cell>
                  <Table.Cell>{item.type}</Table.Cell>
                  <Table.Cell>{item.return_type}</Table.Cell>
                  <Table.Cell>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <DialogRoot size="xl" open={showFormModal} onClose={closeFormModal}>
        <DialogContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <DialogHeader>
              <Heading as="h3" fontSize="lg">
                Variable
              </Heading>
            </DialogHeader>
            <DialogCloseTrigger asChild onClick={closeFormModal} />
            <DialogBody>
              <Stack gap="16px">
                <Field
                  id="label"
                  invalid={errors.label && touched.label}
                  label={
                    <>
                      Label&nbsp;
                      <Tooltip
                        content="Enter a unique name to identify this variable within the Pricing Engine"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                >
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                  {errors.label && touched.label && (
                    <Alert style={{ marginTop: '5px' }} status="error">
                      {errors.label}
                    </Alert>
                  )}
                </Field>
                <Field
                  id="description"
                  label={
                    <>
                      Description&nbsp;
                      <Tooltip
                        content="Provide a detailed description of the purpose and usage of this variable"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  invalid={errors.description && touched.description}
                >
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <Alert style={{ marginTop: '5px' }} status="error">
                      {errors.description}
                    </Alert>
                  )}
                </Field>
                <Field
                  id="uid"
                  invalid={errors.uid && touched.uid}
                  label={
                    <>
                      Name&nbsp;
                      <Tooltip
                        content="Specify the name of the variable to be used in calculations or rules"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                >
                  <Input
                    name="uid"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.uid}
                    readOnly={values.created}
                  />
                  {errors.uid && touched.uid && (
                    <Alert style={{ marginTop: '5px' }} status="error">
                      {errors.uid}
                    </Alert>
                  )}
                </Field>
                <Field
                  id="type"
                  invalid={errors.type && touched.type}
                  label={
                    <>
                      Type&nbsp;
                      <Tooltip
                        content="Select whether the variable type is 'Constant' or 'Function' to define its behavior"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                >
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      name: 'type',
                      value: formatOption(values.type),
                      onChange: type => {
                        setFieldValue('type', type.value);
                        if (type.value === 'function') {
                          setFieldValue('value', DEFAULT_PYTHON_EDITOR);
                        } else {
                          setFieldValue('value', '');
                        }
                      },
                      options: VARIBLE_TYPES.map(formatOption),
                    }}
                  />
                  {errors.type && touched.type && (
                    <Alert style={{ marginTop: '5px' }} status="error">
                      {errors.type}
                    </Alert>
                  )}
                </Field>
                <Field
                  id="return_type"
                  invalid={errors.return_type && touched.return_type}
                  label={
                    <>
                      Return type&nbsp;
                      <Tooltip
                        content="Choose the data type that this variable will return, such as String, Integer, Float, or Boolean."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                >
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      name: 'return_type',
                      value: formatOption(values.return_type),
                      onChange: return_type => {
                        setFieldValue('return_type', return_type.value);
                        if (values.type === 'function') {
                          setFieldValue('value', DEFAULT_PYTHON_EDITOR);
                        } else {
                          setFieldValue('value', '');
                        }
                      },
                      options: VARIBLE_RETURN_TYPES.map(formatOption),
                    }}
                  />
                  {errors.return_type && touched.return_type && (
                    <Alert style={{ marginTop: '5px' }} status="error">
                      {errors.return_type}
                    </Alert>
                  )}
                </Field>
                <Field
                  id="value"
                  invalid={errors.value && touched.value}
                  label={
                    <>
                      Value&nbsp;
                      <Tooltip
                        content="Enter the technical code that defines how this variable will be calculated"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                >
                  {values.type === 'constant' && values.return_type === 'bool' && (
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'value',
                        value: formatOption(values.value),
                        onChange: value => {
                          setFieldValue('value', value.value);
                        },
                        options: ['True', 'False'].map(formatOption),
                      }}
                    />
                  )}
                  {values.type === 'constant' && ['str', 'int', 'float'].includes(values.return_type) && (
                    <Input name="value" type="text" onChange={handleChange} onBlur={handleBlur} value={values.value} />
                  )}
                  {values.type === 'function' && (
                    <CodeMirror
                      value={values.value}
                      height="500px"
                      extensions={[python({})]}
                      onChange={value => {
                        setFieldValue('value', value);
                      }}
                    />
                  )}
                  {errors.value && touched.value && (
                    <Alert style={{ marginTop: '5px' }} status="error">
                      {errors.value}
                    </Alert>
                  )}
                </Field>
              </Stack>
            </DialogBody>
            <DialogFooter>
              <Checkbox
                marginRight="auto"
                checked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" backgroundColor="error.500" onClick={removeVariable} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" disabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};

export default PricingVariables;

PricingVariables.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      return_type: PropTypes.string.isRequired,
    })
  ),
  onSaveVariable: PropTypes.func,
  onRemoveVariable: PropTypes.func,
};
