import React, { useEffect, useState } from 'react';
import { Box, Button, HStack, Input, Stack, Table, VStack } from '@chakra-ui/react';
import { useFormik } from 'formik';
import Datetime from 'react-datetime';
import * as Yup from 'yup';
import Select from '@/components/basic/SelectField';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Radio, RadioGroup } from '@/components/ui/radio';
import { Field } from '@/components/ui/field';
import { Tag } from '@/components/ui/tag';
import PropTypes from 'prop-types';

const PricingFeeRates = ({
  setFeeRates,
  feeRates,
  selectedFee,
  setSelectedFee,
  grinders,
  packerPlants,
  endUsers,
  uoms,
  productTypes,
  currencies,
  incoterms,
  countries,
  ports,
  productCategories,
  coldStores,
  onSaveFeeRate,
  onRemoveFeeRate,
}) => {
  const [packerPlantLabelMap, setPackerPlantLabelMap] = useState();
  const [grinderLabelMap, setGrinderLabelMap] = useState();
  const [endUsersLabelMap, setEndUsersLabelMap] = useState();
  const [uomLabelMap, setUomLabelMap] = useState();
  const [coldStoresLabelMap, setColdStoresLabelMap] = useState();
  const [portsLabelMap, setPortsLabelMap] = useState();
  const [columns] = useState(
    [...selectedFee.lookup_columns, ...selectedFee.state_columns]?.reduce((o, c) => {
      const colParts = c.split(':');
      // eslint-disable-next-line no-param-reassign
      o[colParts.length > 1 ? colParts[1] : colParts[0]] = true;
      return o;
    }, {})
  );
  const [showFormModal, setShowFormModal] = useState(false);

  const closeFormModal = () => setShowFormModal(false);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      end_user_id: columns.end_user_id ? Yup.number().nullable().required('Required') : undefined,
      packer_plant_id: columns.packer_plant_id ? Yup.string().nullable().required('Required') : undefined,
      grinder_uid: columns.grinder_uid ? Yup.string().nullable().required('Required') : undefined,
      product_type: columns.product_type ? Yup.string().nullable().required('Required') : undefined,
      buy_incoterms: columns.buy_incoterms ? Yup.array().nullable().required('Required') : undefined,
      sell_incoterms: columns.sell_incoterms ? Yup.array().nullable().required('Required') : undefined,
      buy_unit_of_measure_id: columns.buy_unit_of_measure_id ? Yup.number().nullable().required('Required') : undefined,
      sell_unit_of_measure_id: columns.sell_unit_of_measure_id
        ? Yup.number().nullable().required('Required')
        : undefined,
      buy_currency: columns.buy_currency ? Yup.string().nullable().required('Required') : undefined,
      sell_currency: columns.sell_currency ? Yup.string().nullable().required('Required') : undefined,
      origin_country: columns.origin_country ? Yup.string().nullable().required('Required') : undefined,
      destination_country: columns.destination_country ? Yup.string().nullable().required('Required') : undefined,
      cold_store_id: columns.cold_store_id ? Yup.number().nullable().required('Required') : undefined,
      product_category: columns.product_category ? Yup.string().nullable().required('Required') : undefined,
      is_grinder_unfunded: columns.is_grinder_unfunded ? Yup.boolean().nullable().required('Required') : undefined,
      cold_store_unit_of_measure_id: columns.cold_store_unit_of_measure_id
        ? Yup.number().nullable().required('Required')
        : undefined,
      cold_store_currency: columns.cold_store_currency ? Yup.string().nullable().required('Required') : undefined,
      from_date: columns.from_date ? Yup.date().nullable().required('Required') : undefined,
      to_date: columns.to_date ? Yup.date().nullable().required('Required') : undefined,
      rate: columns.rate ? Yup.number().nullable().required('Required') : undefined,
      free_days: columns.free_days ? Yup.number().nullable().required('Required') : undefined,
      roll_period_days: columns.roll_period_days ? Yup.number().nullable().required('Required') : undefined,
      destination_port: columns.destination_port ? Yup.number().nullable().required('Required') : undefined,
      origin_port: columns.origin_port ? Yup.number().nullable().required('Required') : undefined,
    }),
    onSubmit: data => {
      onSaveFeeRate(data);
      closeFormModal();
    },
  });

  const openFormModal = (feeRate = {}) => {
    resetForm();
    setValues(feeRate);
    setShowFormModal(true);
  };

  const removeFeeRate = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this item?')) {
      onRemoveFeeRate(values);
      closeFormModal();
    }
  };

  useEffect(() => {
    if (!packerPlants) {
      return;
    }
    setPackerPlantLabelMap(
      packerPlants?.reduce((o, c) => {
        // eslint-disable-next-line no-param-reassign
        o[c.value] = c.label;
        return o;
      }, {})
    );
  }, [packerPlants]);

  useEffect(() => {
    if (!grinders) {
      return;
    }
    setGrinderLabelMap(
      grinders?.reduce((o, c) => {
        // eslint-disable-next-line no-param-reassign
        o[c.value] = c.label;
        return o;
      }, {})
    );
  }, [grinders]);

  useEffect(() => {
    if (!endUsers) {
      return;
    }
    setEndUsersLabelMap(
      endUsers?.reduce((o, c) => {
        // eslint-disable-next-line no-param-reassign
        o[c.value] = c.label;
        return o;
      }, {})
    );
  }, [endUsers]);

  useEffect(() => {
    if (!uoms) {
      return;
    }
    setUomLabelMap(
      uoms?.reduce((o, c) => {
        // eslint-disable-next-line no-param-reassign
        o[c.value] = c.label;
        return o;
      }, {})
    );
  }, [uoms]);

  useEffect(() => {
    if (!coldStores) {
      return;
    }
    setColdStoresLabelMap(
      coldStores?.reduce((o, c) => {
        // eslint-disable-next-line no-param-reassign
        o[c.value] = c.label;
        return o;
      }, {})
    );
  }, [coldStores]);

  useEffect(() => {
    if (!ports) {
      return;
    }
    setPortsLabelMap(
      ports?.reduce((o, c) => {
        // eslint-disable-next-line no-param-reassign
        o[c.value] = c.label;
        return o;
      }, {})
    );
  }, [ports]);

  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button colorScheme="actionPrimary" onClick={() => openFormModal()}>
          New
        </Button>
      </VStack>
      <HStack marginBottom="16px">
        <Button
          type="button"
          colorScheme="actionPrimary"
          onClick={() => {
            setSelectedFee(null);
            setFeeRates(null);
          }}
          marginRight="32px"
        >
          Back
        </Button>
        <p>{selectedFee.label}</p>
      </HStack>
      <Box>
        <Table.Root variant="simple">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Id</Table.ColumnHeader>
              {columns.end_user_id && <Table.ColumnHeader>End user</Table.ColumnHeader>}
              {columns.packer_plant_id && <Table.ColumnHeader>Packer plant</Table.ColumnHeader>}
              {columns.grinder_uid && <Table.ColumnHeader>Grinder</Table.ColumnHeader>}
              {columns.product_type && <Table.ColumnHeader>Product type</Table.ColumnHeader>}
              {columns.buy_incoterms && <Table.ColumnHeader>Buy Incoterms</Table.ColumnHeader>}
              {columns.sell_incoterms && <Table.ColumnHeader>Sell Incoterms</Table.ColumnHeader>}
              {columns.buy_unit_of_measure_id && <Table.ColumnHeader>Buy UOM</Table.ColumnHeader>}
              {columns.sell_unit_of_measure_id && <Table.ColumnHeader>Sell UOM</Table.ColumnHeader>}
              {columns.buy_currency && <Table.ColumnHeader>Buy Currency</Table.ColumnHeader>}
              {columns.sell_currency && <Table.ColumnHeader>Sell Currency</Table.ColumnHeader>}
              {columns.origin_country && <Table.ColumnHeader>Origin Country</Table.ColumnHeader>}
              {columns.destination_country && <Table.ColumnHeader>Destination Country</Table.ColumnHeader>}
              {columns.cold_store_id && <Table.ColumnHeader>Cold Store</Table.ColumnHeader>}
              {columns.product_category && <Table.ColumnHeader>Product category </Table.ColumnHeader>}
              {columns.is_grinder_unfunded && <Table.ColumnHeader>Is Grinder Unfunded</Table.ColumnHeader>}
              {columns.cold_store_unit_of_measure_id && <Table.ColumnHeader>Store UOM</Table.ColumnHeader>}
              {columns.cold_store_currency && <Table.ColumnHeader>Store Currency</Table.ColumnHeader>}
              {columns.origin_port && <Table.ColumnHeader>Origin Port</Table.ColumnHeader>}
              {columns.destination_port && <Table.ColumnHeader>Destination Port</Table.ColumnHeader>}
              {columns.from_date && <Table.ColumnHeader>From date</Table.ColumnHeader>}
              {columns.to_date && <Table.ColumnHeader>To date</Table.ColumnHeader>}
              {columns.rate && <Table.ColumnHeader>Rate</Table.ColumnHeader>}
              {columns.free_days && <Table.ColumnHeader>Free Days</Table.ColumnHeader>}
              {columns.roll_period_days && <Table.ColumnHeader>Roll Period Days</Table.ColumnHeader>}
              <Table.ColumnHeader>&nbsp;</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {feeRates &&
              feeRates.map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.id}</Table.Cell>
                  {columns.end_user_id && <Table.Cell>{endUsersLabelMap?.[item.end_user_id]}</Table.Cell>}
                  {columns.packer_plant_id && <Table.Cell>{packerPlantLabelMap?.[item.packer_plant_id]}</Table.Cell>}
                  {columns.grinder_uid && <Table.Cell>{grinderLabelMap?.[item.grinder_uid]}</Table.Cell>}
                  {columns.product_type && <Table.Cell>{item.product_type}</Table.Cell>}
                  {columns.buy_incoterms && (
                    <Table.Cell>
                      {(item.buy_incoterms || []).map(itm => (
                        <Tag key={itm} marginRight="4px">
                          {itm}
                        </Tag>
                      ))}
                    </Table.Cell>
                  )}
                  {columns.sell_incoterms && (
                    <Table.Cell>
                      {(item.sell_incoterms || []).map(itm => (
                        <Tag key={itm} marginRight="4px">
                          {itm}
                        </Tag>
                      ))}
                    </Table.Cell>
                  )}
                  {columns.buy_unit_of_measure_id && (
                    <Table.Cell>{uomLabelMap?.[item.buy_unit_of_measure_id]}</Table.Cell>
                  )}
                  {columns.sell_unit_of_measure_id && (
                    <Table.Cell>{uomLabelMap?.[item.sell_unit_of_measure_id]}</Table.Cell>
                  )}
                  {columns.buy_currency && <Table.Cell>{item.buy_currency}</Table.Cell>}
                  {columns.sell_currency && <Table.Cell>{item.sell_currency}</Table.Cell>}
                  {columns.origin_country && <Table.Cell>{item.origin_country}</Table.Cell>}
                  {columns.destination_country && <Table.Cell>{item.destination_country}</Table.Cell>}
                  {columns.cold_store_id && <Table.Cell>{coldStoresLabelMap?.[item.cold_store_id]}</Table.Cell>}
                  {columns.product_category && <Table.Cell>{item.product_category}</Table.Cell>}
                  {columns.is_grinder_unfunded && (
                    <Table.Cell>
                      {item.is_grinder_unfunded === true && 'Yes'}
                      {item.is_grinder_unfunded === false && 'No'}
                    </Table.Cell>
                  )}
                  {columns.cold_store_unit_of_measure_id && (
                    <Table.Cell>{uomLabelMap?.[item.cold_store_unit_of_measure_id]}</Table.Cell>
                  )}
                  {columns.cold_store_currency && <Table.Cell>{item.cold_store_currency}</Table.Cell>}
                  {columns.origin_port && <Table.Cell>{portsLabelMap?.[item.origin_port]}</Table.Cell>}
                  {columns.destination_port && <Table.Cell>{portsLabelMap?.[item.destination_port]}</Table.Cell>}
                  {columns.from_date && <Table.Cell>{item.from_date}</Table.Cell>}
                  {columns.to_date && <Table.Cell>{item.to_date}</Table.Cell>}
                  <Table.Cell>{columns.rate && item.rate}</Table.Cell>
                  <Table.Cell>{columns.free_days && item.free_days}</Table.Cell>
                  <Table.Cell>{columns.roll_period_days && item.roll_period_days}</Table.Cell>
                  <Table.Cell>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <DialogRoot size="xl" open={showFormModal} onClose={closeFormModal}>
        <DialogContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <DialogHeader>Fee rate</DialogHeader>
            <DialogCloseTrigger asChild onClick={closeFormModal} />
            <DialogBody>
              <Stack spacing="10">
                {columns.end_user_id && (
                  <Field
                    id="end_user_id"
                    invalid={errors.end_user_id && touched.end_user_id}
                    label="End user *"
                    errorText={errors.end_user_id}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'end_user_id',
                        value: values.end_user_id &&
                          endUsersLabelMap && { id: values.end_user_id, label: endUsersLabelMap?.[values.end_user_id] },
                        onChange: endUser => {
                          setFieldValue('end_user_id', endUser?.value);
                        },
                        options: endUsers || [],
                      }}
                    />
                  </Field>
                )}
                {columns.packer_plant_id && (
                  <Field
                    label="Packer plant *"
                    id="packer_plant_id"
                    invalid={errors.packer_plant_id && touched.packer_plant_id}
                    errorText={errors.packer_plant_id}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'packer_plant_id',
                        value: values.packer_plant_id &&
                          packerPlantLabelMap && {
                            id: values.packer_plant_id,
                            label: packerPlantLabelMap?.[values.packer_plant_id],
                          },
                        onChange: packerPlant => {
                          setFieldValue('packer_plant_id', packerPlant?.value);
                        },
                        options: packerPlants || [],
                      }}
                    />
                  </Field>
                )}
                {columns.grinder_uid && (
                  <Field
                    id="grinder_uid"
                    label="Grinder *"
                    invalid={errors.grinder_uid && touched.grinder_uid}
                    errorText={errors.grinder_uid}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'grinder_uid',
                        value: values.grinder_uid &&
                          grinderLabelMap && { id: values.grinder_uid, label: grinderLabelMap?.[values.grinder_uid] },
                        onChange: grinder => {
                          setFieldValue('grinder_uid', grinder?.value);
                        },
                        options: grinders || [],
                      }}
                    />
                  </Field>
                )}
                {columns.product_type && (
                  <Field
                    id="product_type"
                    label="Product type *"
                    invalid={errors.product_type && touched.product_type}
                    errorText={errors.product_type}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'product_type',
                        value: { id: values.product_type, label: values.product_type },
                        onChange: product_type => {
                          setFieldValue('product_type', product_type?.value);
                        },
                        options: productTypes,
                      }}
                    />
                  </Field>
                )}
                {columns.buy_incoterms && (
                  <Field
                    id="buy_incoterms"
                    label="Buy Incoterms *"
                    invalid={errors.buy_incoterms && touched.buy_incoterms}
                    errorText={errors.buy_incoterms}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: false,
                        removeSelected: true,
                        isMulti: true,
                        name: 'buy_incoterms',
                        value: values.buy_incoterms && values.buy_incoterms.map(item => ({ label: item, value: item })),
                        onChange: buy_incoterms => {
                          setFieldValue(
                            'buy_incoterms',
                            buy_incoterms.map(c => c.value)
                          );
                        },
                        options: incoterms || [],
                      }}
                    />
                  </Field>
                )}
                {columns.sell_incoterms && (
                  <Field
                    id="sell_incoterms"
                    invalid={errors.sell_incoterms && touched.sell_incoterms}
                    label="Sell Incoterms *"
                    errorText={errors.sell_incoterms}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: false,
                        removeSelected: true,
                        isMulti: true,
                        name: 'sell_incoterms',
                        value:
                          values.sell_incoterms && values.sell_incoterms.map(item => ({ label: item, value: item })),
                        onChange: sell_incoterms => {
                          setFieldValue(
                            'sell_incoterms',
                            sell_incoterms.map(c => c.value)
                          );
                        },
                        options: incoterms || [],
                      }}
                    />
                  </Field>
                )}
                {columns.buy_unit_of_measure_id && (
                  <Field
                    id="buy_unit_of_measure_id"
                    label="Buy UOM *"
                    invalid={errors.buy_unit_of_measure_id && touched.buy_unit_of_measure_id}
                    errorText={errors.buy_unit_of_measure_id}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'buy_unit_of_measure_id',
                        value: values.buy_unit_of_measure_id &&
                          uomLabelMap && {
                            id: values.buy_unit_of_measure_id,
                            label: uomLabelMap?.[values.buy_unit_of_measure_id],
                          },
                        onChange: buy_unit_of_measure_id => {
                          setFieldValue('buy_unit_of_measure_id', buy_unit_of_measure_id?.value);
                        },
                        options: uoms || [],
                      }}
                    />
                  </Field>
                )}
                {columns.sell_unit_of_measure_id && (
                  <Field
                    id="sell_unit_of_measure_id"
                    invalid={errors.sell_unit_of_measure_id && touched.sell_unit_of_measure_id}
                    label="Sell UOM *"
                    errorText={errors.sell_unit_of_measure_id}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'sell_unit_of_measure_id',
                        value: values.sell_unit_of_measure_id &&
                          uomLabelMap && {
                            id: values.sell_unit_of_measure_id,
                            label: uomLabelMap?.[values.sell_unit_of_measure_id],
                          },
                        onChange: sell_unit_of_measure_id => {
                          setFieldValue('sell_unit_of_measure_id', sell_unit_of_measure_id?.value);
                        },
                        options: uoms || [],
                      }}
                    />
                  </Field>
                )}
                {columns.buy_currency && (
                  <Field
                    id="buy_currency"
                    label="Buy Currency *"
                    invalid={errors.buy_currency && touched.buy_currency}
                    errorText={errors.buy_currency}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'buy_currency',
                        value: values.buy_currency && { id: values.buy_currency, label: values.buy_currency },
                        onChange: buy_currency => {
                          setFieldValue('buy_currency', buy_currency?.value);
                        },
                        options: currencies || [],
                      }}
                    />
                  </Field>
                )}

                {columns.sell_currency && (
                  <Field
                    id="sell_currency"
                    invalid={errors.sell_currency && touched.sell_currency}
                    label="Sell Currency *"
                    errorText={errors.sell_currency}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'sell_currency',
                        value: values.sell_currency && { id: values.sell_currency, label: values.sell_currency },
                        onChange: sell_currency => {
                          setFieldValue('sell_currency', sell_currency?.value);
                        },
                        options: currencies || [],
                      }}
                    />
                  </Field>
                )}
                {columns.origin_country && (
                  <Field
                    id="origin_country"
                    invalid={errors.origin_country && touched.origin_country}
                    label="Origin Country *"
                    errorText={errors.origin_country}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'origin_country',
                        value: values.origin_country && { id: values.origin_country, label: values.origin_country },
                        onChange: origin_country => {
                          setFieldValue('origin_country', origin_country?.value);
                        },
                        options: countries || [],
                      }}
                    />
                  </Field>
                )}
                {columns.destination_country && (
                  <Field
                    id="destination_country"
                    invalid={errors.destination_country && touched.destination_country}
                    label="Destination Country *"
                    errorText={errors.destination_country}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'destination_country',
                        value: values.destination_country && {
                          id: values.destination_country,
                          label: values.destination_country,
                        },
                        onChange: destination_country => {
                          setFieldValue('destination_country', destination_country?.value);
                        },
                        options: countries || [],
                      }}
                    />
                  </Field>
                )}
                {columns.cold_store_id && (
                  <Field
                    id="cold_store_id"
                    invalid={errors.cold_store_id && touched.cold_store_id}
                    label="Cold Store *"
                    errorText={errors.cold_store_id}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'cold_store_id',
                        value: values.cold_store_id &&
                          coldStoresLabelMap && {
                            id: values.cold_store_id,
                            label: coldStoresLabelMap?.[values.cold_store_id],
                          },
                        onChange: cold_store_id => {
                          setFieldValue('cold_store_id', cold_store_id?.value);
                        },
                        options: coldStores || [],
                      }}
                    />
                  </Field>
                )}
                {columns.product_category && (
                  <Field
                    id="product_category"
                    invalid={errors.product_category && touched.product_category}
                    label="Product Category *"
                    errorText={errors.product_category}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'product_category',
                        value: values.product_category && {
                          id: values.product_category,
                          label: values.product_category,
                        },
                        onChange: product_category => {
                          setFieldValue('product_category', product_category?.value);
                        },
                        options: productCategories || [],
                      }}
                    />
                  </Field>
                )}
                {columns.is_grinder_unfunded && (
                  <Field
                    id="is_grinder_unfunded"
                    invalid={errors.is_grinder_unfunded && touched.is_grinder_unfunded}
                    label="Is Grinder Unfunded *"
                    errorText={errors.is_grinder_unfunded}
                  >
                    <RadioGroup
                      onChange={is_grinder_unfunded =>
                        setFieldValue('is_grinder_unfunded', is_grinder_unfunded === 'true')
                      }
                      value={values.is_grinder_unfunded || ''}
                    >
                      <Stack>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Stack>
                    </RadioGroup>
                  </Field>
                )}
                {columns.cold_store_unit_of_measure_id && (
                  <Field
                    id="cold_store_unit_of_measure_id"
                    invalid={errors.cold_store_unit_of_measure_id && touched.cold_store_unit_of_measure_id}
                    label="Store UOM *"
                    errorText={errors.cold_store_unit_of_measure_id}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'cold_store_unit_of_measure_id',
                        value: values.cold_store_unit_of_measure_id &&
                          uomLabelMap && {
                            id: values.cold_store_unit_of_measure_id,
                            label: uomLabelMap?.[values.cold_store_unit_of_measure_id],
                          },
                        onChange: cold_store_unit_of_measure_id => {
                          setFieldValue('cold_store_unit_of_measure_id', cold_store_unit_of_measure_id?.value);
                        },
                        options: uoms || [],
                      }}
                    />
                  </Field>
                )}
                {columns.cold_store_currency && (
                  <Field
                    id="cold_store_currency"
                    invalid={errors.cold_store_currency && touched.cold_store_currency}
                    label="Store Currency *"
                    errorText={errors.cold_store_currency}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'cold_store_currency',
                        value: values.cold_store_currency && {
                          id: values.cold_store_currency,
                          label: values.cold_store_currency,
                        },
                        onChange: cold_store_currency => {
                          setFieldValue('cold_store_currency', cold_store_currency?.value);
                        },
                        options: currencies || [],
                      }}
                    />
                  </Field>
                )}

                {columns.origin_port && (
                  <Field
                    id="origin_port"
                    invalid={errors.origin_port && touched.origin_port}
                    label="Origin Port *"
                    errorText={errors.origin_port}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'origin_port',

                        value: values.origin_port &&
                          portsLabelMap && { id: values.origin_port, label: portsLabelMap?.[values.origin_port] },
                        onChange: origin_port => {
                          setFieldValue('origin_port', origin_port?.value);
                        },
                        options: ports || [],
                      }}
                    />
                  </Field>
                )}
                {columns.destination_port && (
                  <Field
                    id="destination_port"
                    invalid={errors.destination_port && touched.destination_port}
                    label="Desitnation Port *"
                    errorText={errors.destination_port}
                  >
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        name: 'destination_port',
                        value: values.destination_port &&
                          portsLabelMap && {
                            id: values.destination_port,
                            label: portsLabelMap?.[values.destination_port],
                          },
                        onChange: destination_port => {
                          setFieldValue('destination_port', destination_port?.value);
                        },
                        options: ports || [],
                      }}
                    />
                  </Field>
                )}
                {columns.from_date && (
                  <Field
                    id="from_date"
                    invalid={errors.from_date && touched.from_date}
                    label="From date *"
                    errorText={errors.from_date}
                  >
                    <Datetime
                      name="from_date"
                      inputProps={{
                        className: 'form-control m-input',
                      }}
                      closeOnSelect
                      value={values.from_date}
                      // eslint-disable-next-line no-underscore-dangle
                      onChange={e => setFieldValue('from_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : null)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                  </Field>
                )}
                {columns.to_date && (
                  <Field
                    id="to_date"
                    invalid={errors.to_date && touched.to_date}
                    label="To date *"
                    errorText={errors.to_date}
                  >
                    <Datetime
                      name="to_date"
                      inputProps={{
                        className: 'form-control m-input',
                      }}
                      closeOnSelect
                      value={values.to_date}
                      // eslint-disable-next-line no-underscore-dangle
                      onChange={e => setFieldValue('to_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : null)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                  </Field>
                )}
                {columns.rate && (
                  <Field id="rate" invalid={errors.rate && touched.rate} label="Rate *" errorText={errors.rate}>
                    <Input name="rate" type="number" onChange={handleChange} onBlur={handleBlur} value={values.rate} />
                  </Field>
                )}
                {columns.free_days && (
                  <Field
                    id="free_days"
                    invalid={errors.free_days && touched.free_days}
                    label="Free Days *"
                    errorText={errors.free_days}
                  >
                    <Input
                      name="free_days"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.free_days}
                    />
                  </Field>
                )}
                {columns.roll_period_days && (
                  <Field
                    id="roll_period_days"
                    invalid={errors.roll_period_days && touched.roll_period_days}
                    label="Roll Period Days *"
                    errorText={errors.roll_period_days}
                  >
                    <Input
                      name="roll_period_days"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.roll_period_days}
                    />
                  </Field>
                )}
              </Stack>
            </DialogBody>
            <DialogFooter>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={removeFeeRate} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" disabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};

export default PricingFeeRates;

PricingFeeRates.propTypes = {
  setFeeRates: PropTypes.func,
  feeRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      end_user_id: PropTypes.number,
      packer_plant_id: PropTypes.string,
      grinder_uid: PropTypes.string,
      product_type: PropTypes.string,
      buy_incoterms: PropTypes.arrayOf(PropTypes.string),
      sell_incoterms: PropTypes.arrayOf(PropTypes.string),
      buy_unit_of_measure_id: PropTypes.number,
      sell_unit_of_measure_id: PropTypes.number,
      buy_currency: PropTypes.string,
      sell_currency: PropTypes.string,
      origin_country: PropTypes.string,
      destination_country: PropTypes.string,
      cold_store_id: PropTypes.number,
      product_category: PropTypes.string,
      is_grinder_unfunded: PropTypes.bool,
      cold_store_unit_of_measure_id: PropTypes.number,
      cold_store_currency: PropTypes.string,
      from_date: PropTypes.string,
      to_date: PropTypes.string,
      rate: PropTypes.number,
      free_days: PropTypes.number,
      roll_period_days: PropTypes.number,
      origin_port: PropTypes.number,
      destination_port: PropTypes.number,
    })
  ),
  selectedFee: PropTypes.shape({
    lookup_columns: PropTypes.arrayOf(PropTypes.string),
    state_columns: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string,
  }),
  setSelectedFee: PropTypes.func,
  grinders: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  packerPlants: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  endUsers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  uoms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  incoterms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  ports: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  coldStores: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onSaveFeeRate: PropTypes.func,
  onRemoveFeeRate: PropTypes.func,
};
